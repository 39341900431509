var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "kt_aside",
      staticClass:
        "aside aside-left aside-fixed d-flex flex-column flex-row-auto",
      attrs: { id: "kt_aside" },
      on: { mouseover: _vm.mouseEnter, mouseleave: _vm.mouseLeave },
    },
    [
      _c("KTBrand"),
      _c(
        "div",
        {
          staticClass: "aside-menu-wrapper flex-column-fluid",
          attrs: { id: "kt_aside_menu_wrapper" },
        },
        [
          _c(
            "div",
            {
              ref: "kt_aside_menu",
              staticClass: "aside-menu my-4",
              class: _vm.asideMenuClass,
              attrs: {
                id: "kt_aside_menu",
                "data-menu-vertical": "1",
                "data-menu-dropdown-timeout": "500",
              },
            },
            [
              _c(
                "perfect-scrollbar",
                {
                  staticClass: "aside-menu scroll",
                  staticStyle: { "max-height": "90vh", position: "relative" },
                },
                [_c("KTMenu")],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }