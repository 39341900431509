var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "menu-nav" },
    [
      _c("router-link", {
        attrs: { to: "/dashboard" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ href, navigate, isActive, isExactActive }) {
              return [
                _c(
                  "li",
                  {
                    staticClass: "menu-item",
                    class: [
                      isActive && "menu-item-active",
                      isExactActive && "menu-item-active",
                    ],
                    attrs: {
                      "aria-haspopup": "true",
                      "data-menu-toggle": "hover",
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "menu-link",
                        attrs: { href: href },
                        on: { click: navigate },
                      },
                      [
                        _c("i", {
                          staticClass:
                            "menu-icon flaticon2-architecture-and-city",
                        }),
                        _c("span", { staticClass: "menu-text" }, [
                          _vm._v("Dashboard"),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c("router-link", {
        attrs: { to: "/dailyHoroscope" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ href, navigate }) {
              return [
                _c(
                  "li",
                  {
                    staticClass: "menu-item",
                    class: {
                      "menu-item-open":
                        _vm.hasActiveChildren("/dailyHoroscope"),
                    },
                    attrs: {
                      "aria-haspopup": "true",
                      "data-menu-toggle": "hover",
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "menu-link",
                        attrs: { href: href },
                        on: { click: navigate },
                      },
                      [
                        _c("i", { staticClass: "menu-icon flaticon-list" }),
                        _c("span", { staticClass: "menu-text" }, [
                          _vm._v("Daily Horoscope"),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c("router-link", {
        attrs: { to: "/monthlyHoroscope" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ href, navigate }) {
              return [
                _c(
                  "li",
                  {
                    staticClass: "menu-item",
                    class: {
                      "menu-item-open":
                        _vm.hasActiveChildren("/monthlyHoroscope"),
                    },
                    attrs: {
                      "aria-haspopup": "true",
                      "data-menu-toggle": "hover",
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "menu-link",
                        attrs: { href: href },
                        on: { click: navigate },
                      },
                      [
                        _c("i", { staticClass: "menu-icon flaticon-list-1" }),
                        _c("span", { staticClass: "menu-text" }, [
                          _vm._v("Monthly Horoscope"),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c("router-link", {
        attrs: { to: "/posts" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ href, navigate }) {
              return [
                _c(
                  "li",
                  {
                    staticClass: "menu-item",
                    class: {
                      "menu-item-open": _vm.hasActiveChildren("/post"),
                    },
                    attrs: {
                      "aria-haspopup": "true",
                      "data-menu-toggle": "hover",
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "menu-link",
                        attrs: { href: href },
                        on: { click: navigate },
                      },
                      [
                        _c("i", { staticClass: "menu-icon flaticon-list-2" }),
                        _c("span", { staticClass: "menu-text" }, [
                          _vm._v("Posts"),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c("router-link", {
        attrs: { to: "/notes" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ href, navigate }) {
              return [
                _c(
                  "li",
                  {
                    staticClass: "menu-item",
                    class: {
                      "menu-item-open": _vm.hasActiveChildren("/note"),
                    },
                    attrs: {
                      "aria-haspopup": "true",
                      "data-menu-toggle": "hover",
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "menu-link",
                        attrs: { href: href },
                        on: { click: navigate },
                      },
                      [
                        _c("i", {
                          staticClass: "menu-icon flaticon2-open-text-book",
                        }),
                        _c("span", { staticClass: "menu-text" }, [
                          _vm._v("Notes"),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c("router-link", {
        attrs: { to: "/calendarDays" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ href, navigate }) {
              return [
                _c(
                  "li",
                  {
                    staticClass: "menu-item",
                    class: {
                      "menu-item-open": _vm.hasActiveChildren("/calendarDay"),
                    },
                    attrs: {
                      "aria-haspopup": "true",
                      "data-menu-toggle": "hover",
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "menu-link",
                        attrs: { href: href },
                        on: { click: navigate },
                      },
                      [
                        _c("i", {
                          staticClass:
                            "menu-icon flaticon-event-calendar-symbol",
                        }),
                        _c("span", { staticClass: "menu-text" }, [
                          _vm._v("Calendar Days"),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c("router-link", {
        attrs: { to: "/subscriptions" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ href, navigate }) {
              return [
                _c(
                  "li",
                  {
                    staticClass: "menu-item",
                    class: {
                      "menu-item-open": _vm.hasActiveChildren("/subscription"),
                    },
                    attrs: {
                      "aria-haspopup": "true",
                      "data-menu-toggle": "hover",
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "menu-link",
                        attrs: { href: href },
                        on: { click: navigate },
                      },
                      [
                        _c("i", {
                          staticClass: "menu-icon flaticon-shopping-basket",
                        }),
                        _c("span", { staticClass: "menu-text" }, [
                          _vm._v("Subscriptions"),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c("router-link", {
        attrs: { to: "/settings" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ href, navigate }) {
              return [
                _c(
                  "li",
                  {
                    staticClass: "menu-item",
                    class: {
                      "menu-item-open": _vm.hasActiveChildren("/settings"),
                    },
                    attrs: {
                      "aria-haspopup": "true",
                      "data-menu-toggle": "hover",
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "menu-link",
                        attrs: { href: href },
                        on: { click: navigate },
                      },
                      [
                        _c("i", { staticClass: "menu-icon flaticon-settings" }),
                        _c("span", { staticClass: "menu-text" }, [
                          _vm._v("Settings"),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }