var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "header-mobile align-items-center",
      class: _vm.headerClasses,
      attrs: { id: "kt_header_mobile" },
    },
    [
      _vm._m(0),
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _vm.asideEnabled
          ? _c(
              "button",
              {
                staticClass: "btn p-0 burger-icon burger-icon-left",
                attrs: { id: "kt_aside_mobile_toggle" },
              },
              [_c("span")]
            )
          : _vm._e(),
        _c(
          "button",
          {
            ref: "kt_header_mobile_toggle",
            staticClass: "btn p-0 burger-icon ml-4",
            attrs: { id: "kt_header_mobile_toggle" },
          },
          [_c("span")]
        ),
        _c(
          "button",
          {
            ref: "kt_header_mobile_topbar_toggle",
            staticClass: "btn btn-hover-text-primary p-0 ml-2",
            attrs: { id: "kt_header_mobile_topbar_toggle" },
          },
          [
            _c(
              "span",
              { staticClass: "svg-icon svg-icon-xl" },
              [
                _c("inline-svg", {
                  staticClass: "svg-icon",
                  attrs: { src: "media/svg/icons/General/User.svg" },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "/" } }, [
      _c("span", { staticClass: "logo" }, [_vm._v("AstrologyZone CMS")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }