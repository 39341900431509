<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <!--begin: Language bar -->
    <div class="topbar-item">
      <b-dropdown
        size="sm"
        variant="link"
        toggle-class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 text-decoration-none"
        no-caret
        right
        no-flip
      >
        <template v-slot:button-content>
          <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
            <span class="svg-icon svg-icon-xl svg-icon-primary">
              <inline-svg src="media/svg/icons/General/User.svg" />
            </span>
          </div>
        </template>
        <b-dropdown-item @click="onLogout">Sign out</b-dropdown-item>
      </b-dropdown>
    </div>
    <!--end: Language bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import { LOGOUT } from "@/core/services/store/auth.module";

export default {
  name: "KTTopbar",
  data() {
    return {};
  },
  components: {},
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    }
  },
  computed: {}
};
</script>
