<template>
  <ul class="menu-nav">
    <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link>

    <router-link to="/dailyHoroscope" v-slot="{ href, navigate }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/dailyHoroscope')
        }"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-list"></i>
          <span class="menu-text">Daily Horoscope</span>
        </a>
      </li>
    </router-link>

    <router-link to="/monthlyHoroscope" v-slot="{ href, navigate }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/monthlyHoroscope')
        }"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-list-1"></i>
          <span class="menu-text">Monthly Horoscope</span>
        </a>
      </li>
    </router-link>

    <router-link to="/posts" v-slot="{ href, navigate }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/post')
        }"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-list-2"></i>
          <span class="menu-text">Posts</span>
        </a>
      </li>
    </router-link>

    <router-link to="/notes" v-slot="{ href, navigate }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/note')
        }"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-open-text-book"></i>
          <span class="menu-text">Notes</span>
        </a>
      </li>
    </router-link>

    <router-link to="/calendarDays" v-slot="{ href, navigate }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/calendarDay')
        }"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-event-calendar-symbol"></i>
          <span class="menu-text">Calendar Days</span>
        </a>
      </li>
    </router-link>

    <router-link to="/subscriptions" v-slot="{ href, navigate }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/subscription')
        }"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-shopping-basket"></i>
          <span class="menu-text">Subscriptions</span>
        </a>
      </li>
    </router-link>

    <router-link to="/settings" v-slot="{ href, navigate }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/settings')
        }"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-settings"></i>
          <span class="menu-text">Settings</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
