var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "menu-nav" },
    [
      _c("router-link", {
        attrs: { to: "/dashboard" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ href, navigate, isActive, isExactActive }) {
              return [
                _c(
                  "li",
                  {
                    staticClass: "menu-item",
                    class: [
                      isActive && "menu-item-active",
                      isExactActive && "menu-item-active",
                    ],
                    attrs: {
                      "aria-haspopup": "true",
                      "data-menu-toggle": "hover",
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "menu-link",
                        attrs: { href: href },
                        on: { click: navigate },
                      },
                      [
                        _c("span", { staticClass: "menu-text" }, [
                          _vm._v(" Dashboard "),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c("router-link", {
        attrs: { to: "/builder" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ href, navigate, isActive, isExactActive }) {
              return [
                _c(
                  "li",
                  {
                    staticClass: "menu-item",
                    class: [
                      isActive && "menu-item-active",
                      isExactActive && "menu-item-active",
                    ],
                    attrs: {
                      "aria-haspopup": "true",
                      "data-menu-toggle": "hover",
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "menu-link",
                        attrs: { href: href },
                        on: { click: navigate },
                      },
                      [
                        _c("span", { staticClass: "menu-text" }, [
                          _vm._v(" Builder "),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "li",
        {
          staticClass: "menu-item menu-item-submenu menu-item-open-dropdown",
          class: {
            "menu-item-active": _vm.hasActiveChildren("/vue-bootstrap"),
          },
          attrs: { "aria-haspopup": "true", "data-menu-toggle": "click" },
        },
        [
          _vm._m(0),
          _c("div", { staticClass: "menu-submenu menu-submenu-fixed" }, [
            _c(
              "div",
              {
                staticClass: "menu-subnav megamenu",
                staticStyle: { width: "1000px" },
              },
              [
                _c("ul", { staticClass: "menu-content" }, [
                  _c("li", { staticClass: "menu-item" }, [
                    _vm._m(1),
                    _c(
                      "ul",
                      { staticClass: "menu-inner" },
                      [
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/alert" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Alert ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/badge" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Badge ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/button" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Button ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/button-group" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Button Group ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/button-toolbar" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Button Toolbar ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/card" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Card ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/carousel" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Carousel ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("li", { staticClass: "menu-item" }, [
                    _vm._m(2),
                    _c(
                      "ul",
                      { staticClass: "menu-inner" },
                      [
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/collapse" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Collapse ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/dropdown" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Dropdown ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/embed" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Embed ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/form" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Form ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/form-checkbox" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Form Checkbox ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/form-file" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Form File ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/form-group" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Form Group ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/form-input" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Form Input ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("li", { staticClass: "menu-item" }, [
                    _vm._m(3),
                    _c(
                      "ul",
                      { staticClass: "menu-inner" },
                      [
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/form-radio" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Form Radio ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/form-select" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Form Select ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/form-textarea" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Form Textarea ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/image" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Image ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/input-group" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Input Group ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/jumbotron" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Jumbotron ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._m(4),
                      ],
                      1
                    ),
                  ]),
                  _c("li", { staticClass: "menu-item" }, [
                    _vm._m(5),
                    _c(
                      "ul",
                      { staticClass: "menu-inner" },
                      [
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/link" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Link ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/list-group" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" List Group ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/media" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Media ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/modal" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Modal ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/nav" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Nav ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/navbar" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Navbar ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/pagination" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Pagination ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/pagination-nav" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Pagination Nav ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("li", { staticClass: "menu-item" }, [
                    _vm._m(6),
                    _c(
                      "ul",
                      { staticClass: "menu-inner" },
                      [
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/popover" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Popover ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/progress" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Progress ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/spinner" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Spinner ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/table" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Table ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/tabs" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Tabs ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/toasts" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Toasts ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vue-bootstrap/tooltip" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Tooltip ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ]
      ),
      _c(
        "li",
        {
          staticClass: "menu-item menu-item-submenu menu-item-open-dropdown",
          class: { "menu-item-active": _vm.hasActiveChildren("vuetify") },
          attrs: { "aria-haspopup": "true", "data-menu-toggle": "click" },
        },
        [
          _vm._m(7),
          _c("div", { staticClass: "menu-submenu menu-submenu-fixed" }, [
            _c(
              "div",
              {
                staticClass: "menu-subnav megamenu",
                staticStyle: { width: "800px" },
              },
              [
                _c("ul", { staticClass: "menu-content" }, [
                  _c("li", { staticClass: "menu-item" }, [
                    _vm._m(8),
                    _c(
                      "ul",
                      { staticClass: "menu-inner" },
                      [
                        _c("router-link", {
                          attrs: { to: "/vuetify/alerts" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Alerts ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vuetify/avatars" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Avatars ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vuetify/badges" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Badges ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vuetify/buttons" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Buttons ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vuetify/calendars" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Calendars ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("li", { staticClass: "menu-item" }, [
                    _vm._m(9),
                    _c(
                      "ul",
                      { staticClass: "menu-inner" },
                      [
                        _c("router-link", {
                          attrs: { to: "/vuetify/cards" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Cards ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vuetify/chips" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Chips ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vuetify/dialog" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Dialog ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vuetify/autocompletes" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Autocompletes ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vuetify/file-inputs" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" File Inputs ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("li", { staticClass: "menu-item" }, [
                    _vm._m(10),
                    _c(
                      "ul",
                      { staticClass: "menu-inner" },
                      [
                        _c("router-link", {
                          attrs: { to: "/vuetify/forms" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Forms ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vuetify/selection-controls" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Selection Controls ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vuetify/selects" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Selects ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vuetify/textareas" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Textareas ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vuetify/text-fields" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Text Fields ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("li", { staticClass: "menu-item" }, [
                    _vm._m(11),
                    _c(
                      "ul",
                      { staticClass: "menu-inner" },
                      [
                        _c("router-link", {
                          attrs: { to: "/vuetify/simple-tables" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Simple Tables ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vuetify/data-tables" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Data Tables ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vuetify/tabs" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Tabs ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vuetify/timelines" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Timelines ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vuetify/tooltips" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Tooltips ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("router-link", {
                          attrs: { to: "/vuetify/treeview" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                href,
                                navigate,
                                isActive,
                                isExactActive,
                              }) {
                                return [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      class: [
                                        isActive && "menu-item-active",
                                        isExactActive && "menu-item-active",
                                      ],
                                      attrs: { "aria-haspopup": "true" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "menu-link",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "menu-bullet menu-bullet-dot",
                                            },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "menu-text" },
                                            [_vm._v(" Treeview ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ]
      ),
      _c(
        "li",
        {
          staticClass: "menu-item menu-item-submenu menu-item-open-dropdown",
          class: { "menu-item-active": _vm.hasActiveChildren("/error/error") },
          attrs: { "aria-haspopup": "true", "data-menu-toggle": "click" },
        },
        [
          _vm._m(12),
          _c("div", { staticClass: "menu-submenu menu-submenu-classic" }, [
            _c("ul", { staticClass: "menu-subnav" }, [
              _c(
                "li",
                {
                  staticClass: "menu-item menu-item-submenu",
                  attrs: {
                    "aria-haspopup": "true",
                    "data-menu-toggle": "hover",
                  },
                },
                [
                  _vm._m(13),
                  _c(
                    "div",
                    {
                      staticClass:
                        "menu-submenu menu-submenu-classic menu-submenu-right",
                    },
                    [
                      _c(
                        "ul",
                        { staticClass: "menu-subnav" },
                        [
                          _c("router-link", {
                            attrs: { to: "/error/error-1" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({
                                  href,
                                  navigate,
                                  isActive,
                                  isExactActive,
                                }) {
                                  return [
                                    _c(
                                      "li",
                                      {
                                        staticClass: "menu-item",
                                        class: [
                                          isActive && "menu-item-active",
                                          isExactActive && "menu-item-active",
                                        ],
                                        attrs: {
                                          "aria-haspopup": "true",
                                          "data-menu-toggle": "hover",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "menu-link",
                                            attrs: { href: href },
                                            on: { click: navigate },
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "menu-bullet menu-bullet-dot",
                                              },
                                              [_c("span")]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "menu-text" },
                                              [_vm._v(" Error 1 ")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("router-link", {
                            attrs: { to: "/error/error-2" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({
                                  href,
                                  navigate,
                                  isActive,
                                  isExactActive,
                                }) {
                                  return [
                                    _c(
                                      "li",
                                      {
                                        staticClass: "menu-item",
                                        class: [
                                          isActive && "menu-item-active",
                                          isExactActive && "menu-item-active",
                                        ],
                                        attrs: {
                                          "aria-haspopup": "true",
                                          "data-menu-toggle": "hover",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "menu-link",
                                            attrs: { href: href },
                                            on: { click: navigate },
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "menu-bullet menu-bullet-dot",
                                              },
                                              [_c("span")]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "menu-text" },
                                              [_vm._v(" Error 2 ")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("router-link", {
                            attrs: { to: "/error/error-3" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({
                                  href,
                                  navigate,
                                  isActive,
                                  isExactActive,
                                }) {
                                  return [
                                    _c(
                                      "li",
                                      {
                                        staticClass: "menu-item",
                                        class: [
                                          isActive && "menu-item-active",
                                          isExactActive && "menu-item-active",
                                        ],
                                        attrs: {
                                          "aria-haspopup": "true",
                                          "data-menu-toggle": "hover",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "menu-link",
                                            attrs: { href: href },
                                            on: { click: navigate },
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "menu-bullet menu-bullet-dot",
                                              },
                                              [_c("span")]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "menu-text" },
                                              [_vm._v(" Error 3 ")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("router-link", {
                            attrs: { to: "/error/error-4" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({
                                  href,
                                  navigate,
                                  isActive,
                                  isExactActive,
                                }) {
                                  return [
                                    _c(
                                      "li",
                                      {
                                        staticClass: "menu-item",
                                        class: [
                                          isActive && "menu-item-active",
                                          isExactActive && "menu-item-active",
                                        ],
                                        attrs: {
                                          "aria-haspopup": "true",
                                          "data-menu-toggle": "hover",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "menu-link",
                                            attrs: { href: href },
                                            on: { click: navigate },
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "menu-bullet menu-bullet-dot",
                                              },
                                              [_c("span")]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "menu-text" },
                                              [_vm._v(" Error 4 ")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("router-link", {
                            attrs: { to: "/error/error-5" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({
                                  href,
                                  navigate,
                                  isActive,
                                  isExactActive,
                                }) {
                                  return [
                                    _c(
                                      "li",
                                      {
                                        staticClass: "menu-item",
                                        class: [
                                          isActive && "menu-item-active",
                                          isExactActive && "menu-item-active",
                                        ],
                                        attrs: {
                                          "aria-haspopup": "true",
                                          "data-menu-toggle": "hover",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "menu-link",
                                            attrs: { href: href },
                                            on: { click: navigate },
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "menu-bullet menu-bullet-dot",
                                              },
                                              [_c("span")]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "menu-text" },
                                              [_vm._v(" Error 5 ")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("router-link", {
                            attrs: { to: "/error/error-6" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({
                                  href,
                                  navigate,
                                  isActive,
                                  isExactActive,
                                }) {
                                  return [
                                    _c(
                                      "li",
                                      {
                                        staticClass: "menu-item",
                                        class: [
                                          isActive && "menu-item-active",
                                          isExactActive && "menu-item-active",
                                        ],
                                        attrs: {
                                          "aria-haspopup": "true",
                                          "data-menu-toggle": "hover",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "menu-link",
                                            attrs: { href: href },
                                            on: { click: navigate },
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "menu-bullet menu-bullet-dot",
                                              },
                                              [_c("span")]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "menu-text" },
                                              [_vm._v(" Error 6 ")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "li",
                {
                  staticClass: "menu-item menu-item-submenu",
                  class: {
                    "menu-item-active": _vm.hasActiveChildren("/wizard/wizard"),
                  },
                  attrs: {
                    "aria-haspopup": "true",
                    "data-menu-toggle": "hover",
                  },
                },
                [
                  _vm._m(14),
                  _c(
                    "div",
                    {
                      staticClass:
                        "menu-submenu menu-submenu-classic menu-submenu-right",
                    },
                    [
                      _c(
                        "ul",
                        { staticClass: "menu-subnav" },
                        [
                          _c("router-link", {
                            attrs: { to: "/wizard/wizard-1" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({
                                  href,
                                  navigate,
                                  isActive,
                                  isExactActive,
                                }) {
                                  return [
                                    _c(
                                      "li",
                                      {
                                        staticClass: "menu-item",
                                        class: [
                                          isActive && "menu-item-active",
                                          isExactActive && "menu-item-active",
                                        ],
                                        attrs: {
                                          "aria-haspopup": "true",
                                          "data-menu-toggle": "hover",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "menu-link",
                                            attrs: { href: href },
                                            on: { click: navigate },
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "menu-bullet menu-bullet-dot",
                                              },
                                              [_c("span")]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "menu-text" },
                                              [_vm._v(" Wizard 1 ")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("router-link", {
                            attrs: { to: "/wizard/wizard-2" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({
                                  href,
                                  navigate,
                                  isActive,
                                  isExactActive,
                                }) {
                                  return [
                                    _c(
                                      "li",
                                      {
                                        staticClass: "menu-item",
                                        class: [
                                          isActive && "menu-item-active",
                                          isExactActive && "menu-item-active",
                                        ],
                                        attrs: {
                                          "aria-haspopup": "true",
                                          "data-menu-toggle": "hover",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "menu-link",
                                            attrs: { href: href },
                                            on: { click: navigate },
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "menu-bullet menu-bullet-dot",
                                              },
                                              [_c("span")]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "menu-text" },
                                              [_vm._v(" Wizard 2 ")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("router-link", {
                            attrs: { to: "/wizard/wizard-3" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({
                                  href,
                                  navigate,
                                  isActive,
                                  isExactActive,
                                }) {
                                  return [
                                    _c(
                                      "li",
                                      {
                                        staticClass: "menu-item",
                                        class: [
                                          isActive && "menu-item-active",
                                          isExactActive && "menu-item-active",
                                        ],
                                        attrs: {
                                          "aria-haspopup": "true",
                                          "data-menu-toggle": "hover",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "menu-link",
                                            attrs: { href: href },
                                            on: { click: navigate },
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "menu-bullet menu-bullet-dot",
                                              },
                                              [_c("span")]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "menu-text" },
                                              [_vm._v(" Wizard 3 ")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("router-link", {
                            attrs: { to: "/wizard/wizard-4" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({
                                  href,
                                  navigate,
                                  isActive,
                                  isExactActive,
                                }) {
                                  return [
                                    _c(
                                      "li",
                                      {
                                        staticClass: "menu-item",
                                        attrs: {
                                          "aria-haspopup": "true",
                                          "data-menu-toggle": "hover",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "menu-link",
                                            attrs: {
                                              href: "#/wizard/wizard-4",
                                            },
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "menu-bullet menu-bullet-dot",
                                              },
                                              [_c("span")]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "menu-text" },
                                              [_vm._v(" Wizard 4 ")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      { staticClass: "menu-link menu-toggle", attrs: { href: "#" } },
      [_c("span", { staticClass: "menu-text" }, [_vm._v(" Vue Bootstrap ")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "menu-heading menu-toggle" }, [
      _c("i", { staticClass: "menu-bullet menu-bullet-dot" }, [_c("span")]),
      _c("span", { staticClass: "menu-text" }, [_vm._v(" Task Reports ")]),
      _c("i", { staticClass: "menu-arrow" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "menu-heading menu-toggle" }, [
      _c("i", { staticClass: "menu-bullet menu-bullet-dot" }, [_c("span")]),
      _c("span", { staticClass: "menu-text" }, [_vm._v(" Profit Margins ")]),
      _c("i", { staticClass: "menu-arrow" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "menu-heading menu-toggle" }, [
      _c("i", { staticClass: "menu-bullet menu-bullet-dot" }, [_c("span")]),
      _c("span", { staticClass: "menu-text" }, [_vm._v(" Staff Management ")]),
      _c("i", { staticClass: "menu-arrow" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "li",
      { staticClass: "menu-item", attrs: { "aria-haspopup": "true" } },
      [
        _c(
          "a",
          {
            staticClass: "menu-link",
            attrs: { href: "#/vue-bootstrap/layout-grid-system" },
          },
          [
            _c("i", { staticClass: "menu-bullet menu-bullet-dot" }, [
              _c("span"),
            ]),
            _c("span", { staticClass: "menu-text" }, [
              _vm._v(" Layout Grid System "),
            ]),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "menu-heading menu-toggle" }, [
      _c("i", { staticClass: "menu-bullet menu-bullet-dot" }, [_c("span")]),
      _c("span", { staticClass: "menu-text" }, [_vm._v(" Tools ")]),
      _c("i", { staticClass: "menu-arrow" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "menu-heading menu-toggle" }, [
      _c("i", { staticClass: "menu-bullet menu-bullet-dot" }, [_c("span")]),
      _c("span", { staticClass: "menu-text" }, [_vm._v(" Misc ")]),
      _c("i", { staticClass: "menu-arrow" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      { staticClass: "menu-link menu-toggle", attrs: { href: "#" } },
      [_c("span", { staticClass: "menu-text" }, [_vm._v(" Vuetify ")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "menu-heading menu-toggle" }, [
      _c("i", { staticClass: "menu-bullet menu-bullet-dot" }, [_c("span")]),
      _c("span", { staticClass: "menu-text" }, [_vm._v(" Task Reports ")]),
      _c("i", { staticClass: "menu-arrow" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "menu-heading menu-toggle" }, [
      _c("i", { staticClass: "menu-bullet menu-bullet-dot" }, [_c("span")]),
      _c("span", { staticClass: "menu-text" }, [_vm._v(" Profit Margins ")]),
      _c("i", { staticClass: "menu-arrow" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "menu-heading menu-toggle" }, [
      _c("i", { staticClass: "menu-bullet menu-bullet-dot" }, [_c("span")]),
      _c("span", { staticClass: "menu-text" }, [_vm._v(" Staff Management ")]),
      _c("i", { staticClass: "menu-arrow" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "menu-heading menu-toggle" }, [
      _c("i", { staticClass: "menu-bullet menu-bullet-dot" }, [_c("span")]),
      _c("span", { staticClass: "menu-text" }, [_vm._v(" Tools ")]),
      _c("i", { staticClass: "menu-arrow" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      { staticClass: "menu-link menu-toggle", attrs: { href: "#" } },
      [_c("span", { staticClass: "menu-text" }, [_vm._v(" Custom ")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      { staticClass: "menu-link menu-toggle", attrs: { href: "#" } },
      [
        _c("i", { staticClass: "menu-icon flaticon2-list-2" }),
        _c("span", { staticClass: "menu-text" }, [_vm._v(" Error Pages ")]),
        _c("i", { staticClass: "menu-arrow" }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      { staticClass: "menu-link menu-toggle", attrs: { href: "#" } },
      [
        _c("i", { staticClass: "menu-icon flaticon2-mail-1" }),
        _c("span", { staticClass: "menu-text" }, [_vm._v(" Wizard ")]),
        _c("i", { staticClass: "menu-arrow" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }