var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "topbar" }, [
    _c(
      "div",
      { staticClass: "topbar-item" },
      [
        _c(
          "b-dropdown",
          {
            attrs: {
              size: "sm",
              variant: "link",
              "toggle-class":
                "btn btn-icon btn-clean btn-dropdown btn-lg mr-1 text-decoration-none",
              "no-caret": "",
              right: "",
              "no-flip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "button-content",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "btn btn-icon btn-clean btn-dropdown btn-lg mr-1",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "svg-icon svg-icon-xl svg-icon-primary",
                          },
                          [
                            _c("inline-svg", {
                              attrs: {
                                src: "media/svg/icons/General/User.svg",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            _c("b-dropdown-item", { on: { click: _vm.onLogout } }, [
              _vm._v("Sign out"),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }